import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { TextField, Slider, Button, Divider } from "@mui/material"
import { VscSymbolString } from "react-icons/vsc"
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa"
import { IoIosCopy } from "react-icons/io"
import { MdRestore } from "react-icons/md"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Section from "../../components/section"
import getSiteColor from "../../functions/get-site-color"
import randomString from "../../static/random-string"
import { setCurrentPage } from "../../states/slices/main-slice"
import { playSound } from "../../states/slices/sound-slice"

const Flex = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  position: relative;
`

const Label = styled.span`
  font-size: 1rem;
  margin-bottom: 0.25rem;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme }) =>
      theme === `light` ? `rgba(0, 0, 0, 0.12)` : `rgba(255, 255, 255, 0.12)`};
  border-radius: 8px;
  margin: 4px;
  padding: 8px;
  min-width: 45px;
  font-size: 0.85rem;
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) =>
    theme === `light` ? `rgba(0, 0, 0, 0.8)` : `rgba(255, 255, 255, 0.8)`};
  transition: border 0.3s, background-color 0.3s, color 0.3s, transform 0.1s;

  &:hover,
  &.selected {
    background-color: ${({ theme, siteColor }) =>
      getSiteColor(siteColor, theme)};
    color: ${({ theme }) => (theme === `light` ? `#fff` : `#000`)};
    transition: border 0.1s, background-color 0.1s, color 0.1s;
  }

  &:active {
    transform: scale(0.95);
  }

  svg {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`

const Part = ({ children, label }) => {
  return (
    <div
      style={{
        marginBottom: `1rem`,
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      {label !== `` && <Label>{label}</Label>}
      {children}
    </div>
  )
}

Part.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
}

Part.defaultProps = {
  label: ``,
}

const RandomString = () => {
  const { lang, theme, siteColor } = useSelector(
    ({ mainReducer }) => mainReducer
  )
  const dispatch = useDispatch()

  const [length, setLength] = useState(8)
  const [charset, setCharset] = useState(`alphanumeric`)
  const [capitalization, setCapitalization] = useState(`any`)
  const [customChar, setCustomChar] = useState(``)
  const [randomText, setRandomText] = useState(``)
  const [isCopied, setIsCopied] = useState(false)
  const textInputRef = useRef(null)

  useEffect(() => {
    dispatch(
      setCurrentPage({
        page: `random-string`,
        group: `app`,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (charset !== `custom`) {
      setCustomChar(``)
    }
  }, [charset])

  const selectCharset = val => {
    dispatch(playSound(`confirm`))

    setCharset(val)
  }

  const selectCapitalization = val => {
    dispatch(playSound(`confirm`))

    setCapitalization(val)
  }

  const setDefault = () => {
    setLength(8)
    setCharset(`alphanumeric`)
    setCapitalization(`any`)
    setCustomChar(``)
    setRandomText(``)
    setIsCopied(false)
  }

  const renderCharacters = () => {
    switch (charset) {
      case `alphanumeric`:
        return `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`

      case `alphabetic`:
        return `abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`

      case `numeric`:
        return `0123456789`

      case `hex`:
        return `0123456789abcdef`

      case `binary`:
        return `01`

      case `custom`:
        return customChar

      default:
        return `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`
    }
  }

  const patchCapitalization = getText => {
    switch (capitalization) {
      case `lowercase`:
        return getText.toLowerCase()

      case `uppercase`:
        return getText.toUpperCase()

      default:
        return getText
    }
  }

  const goRandom = () => {
    let result = ``
    const characters = renderCharacters()
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }

    result = patchCapitalization(result)
    setRandomText(result)
    setIsCopied(false)
  }

  const copyToClipboard = e => {
    textInputRef.current.select()
    document.execCommand("copy")
    setIsCopied(true)
  }

  return (
    <Layout>
      <Seo title={randomString.pageName[lang]} />
      <Section title={randomString.pageName[lang]} icon={<VscSymbolString />}>
        <div style={{ width: `100%` }}>
          <Flex>
            <TextField
              inputRef={textInputRef}
              size="small"
              variant="outlined"
              color="primary"
              fullWidth
              multiline
              disabled={randomText === ``}
              style={{ marginBottom: `2rem` }}
              InputProps={{
                readOnly: true,
                style: { transition: `0.3s` },
                endAdornment:
                  randomText !== `` ? (
                    isCopied ? (
                      <span
                        style={{
                          whiteSpace: `nowrap`,
                          fontSize: `0.75rem`,
                          opacity: 0.5,
                        }}
                      >
                        {randomString.copied[lang]}
                      </span>
                    ) : (
                      <IoIosCopy
                        style={{
                          fontSize: 18,
                          opacity: 0.75,
                          cursor: `pointer`,
                        }}
                      />
                    )
                  ) : null,
              }}
              value={randomText}
              onClick={() => {
                if (randomText !== ``) {
                  dispatch(playSound(`confirm`))
                  copyToClipboard()
                }
              }}
            />
            <Part label={randomString.length[lang]}>
              <Slider
                aria-labelledby="discrete-slider"
                valueLabelDisplay="auto"
                step={1}
                min={1}
                max={100}
                value={length}
                onChange={(_, newValue) => setLength(newValue)}
                onChangeCommitted={() => dispatch(playSound(`click`))}
              />
            </Part>
            <Part label={randomString.charset[lang]}>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  flexWrap: `wrap`,
                }}
              >
                <Block
                  className={charset === `alphanumeric` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`alphanumeric`)}
                >
                  {randomString.charsetMenu.alphanumeric[lang]} [0-9 a-z A-Z]
                </Block>

                <Block
                  className={charset === `alphabetic` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`alphabetic`)}
                >
                  {randomString.charsetMenu.alphabetic[lang]} [a-z A-Z]
                </Block>
                <Block
                  className={charset === `numeric` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`numeric`)}
                >
                  {randomString.charsetMenu.numeric[lang]} [0-9]
                </Block>
                <Block
                  className={charset === `hex` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`hex`)}
                >
                  {randomString.charsetMenu.hex[lang]} [0-9 a-f]
                </Block>
                <Block
                  className={charset === `binary` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`binary`)}
                >
                  {randomString.charsetMenu.binary[lang]} [01]
                </Block>
                <Block
                  className={charset === `custom` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCharset(`custom`)}
                >
                  {randomString.charsetMenu.custom[lang]}
                </Block>
                {charset === `custom` && (
                  <TextField
                    style={{ marginTop: `0.5rem` }}
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    multiline
                    InputProps={{
                      style: { transition: `0.3s` },
                      endAdornment:
                        customChar !== `` ? (
                          <FaCheckCircle
                            style={{ color: getSiteColor(`green`, theme) }}
                          />
                        ) : (
                          <FaRegCheckCircle style={{ opacity: 0.75 }} />
                        ),
                    }}
                    value={customChar}
                    onChange={e => setCustomChar(e.target.value)}
                  />
                )}
              </div>
            </Part>
            <Part label={randomString.capitalization[lang]}>
              <div
                style={{
                  display: `flex`,
                  width: `100%`,
                  flexWrap: `wrap`,
                }}
              >
                <Block
                  className={capitalization === `any` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCapitalization(`any`)}
                >
                  {randomString.capitalizationMenu.any[lang]}
                </Block>

                <Block
                  className={capitalization === `lowercase` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCapitalization(`lowercase`)}
                >
                  {randomString.capitalizationMenu.lowercase[lang]}
                </Block>
                <Block
                  className={capitalization === `uppercase` ? `selected` : ``}
                  theme={theme}
                  siteColor={siteColor}
                  role="presentation"
                  onClick={() => selectCapitalization(`uppercase`)}
                >
                  {randomString.capitalizationMenu.uppercase[lang]}
                </Block>
              </div>
              <Divider style={{ margin: `1rem 0` }} />
              <div style={{ display: `flex`, justifyContent: `space-between` }}>
                <Button
                  style={{ transition: `0.3s` }}
                  onClick={() => {
                    dispatch(playSound(`cancel`))
                    setDefault()
                  }}
                  color="primary"
                  disabled={
                    length === 8 &&
                    charset === `alphanumeric` &&
                    capitalization === `any` &&
                    randomText === ``
                  }
                >
                  <MdRestore style={{ marginRight: 5, fontSize: 20 }} />{" "}
                  {randomString.restore[lang]}
                </Button>
                <Button
                  style={{ transition: `0.3s` }}
                  onClick={() => {
                    dispatch(playSound(`confirm`))
                    goRandom()
                  }}
                  color="primary"
                  variant="contained"
                  disabled={charset === `custom` && customChar === ``}
                >
                  <VscSymbolString style={{ marginRight: 5, fontSize: 20 }} />{" "}
                  {randomString.random[lang]}
                </Button>
              </div>
            </Part>
          </Flex>
        </div>
      </Section>
    </Layout>
  )
}

export default RandomString
