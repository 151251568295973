import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import styled from "styled-components"

import ShiningEffect from "./shining-effect"
import getSiteColor from "../functions/get-site-color"

const borderRadius = 8
const SectionBlock = styled.div`
  background-color: ${({ theme }) =>
    theme === `light` ? `rgb(255, 255, 255)` : `rgb(45, 45, 45)`};
  padding: 1rem 1.5rem;
  border-radius: 24px;
  box-shadow: 0 0 0 1px
    ${({ theme }) => (theme === `light` ? `#dddfe2ff` : `#dddfe23c`)};
  transition: background-color 0.3s, box-shadow 0.3s;

  div.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    p {
      position: relative;
      border-radius: ${borderRadius}px;
      text-align: center;
      font-size: 2rem;
      margin: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: ${({ theme, siteColor }) => getSiteColor(siteColor, theme)};
      transition: 0.3s;

      svg {
        font-size: 1.75rem;
        margin-right: 0.75rem;
      }
    }
  }

  div.description {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 399px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.75rem;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    div.description-left {
      width: 50%;
      display: flex;
      justify-content: flex-end;

      p {
        margin-right: 1.5rem;
      }
    }

    div.description-right {
      width: 50%;
      display: flex;
      justify-content: flex-start;

      svg {
        margin-right: 0.25rem;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0.75rem 1.5rem;

    div.title {
      p {
        font-size: 1.5rem;

        svg {
          font-size: 1.25rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
`

const Section = ({ title, titleStyle, icon, blink, children, style }) => {
  const { theme, siteColor } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <SectionBlock theme={theme} siteColor={siteColor} style={style}>
      {title !== `` && (
        <div className="title" style={titleStyle}>
          <p>
            {icon !== undefined && <span>{icon}</span>}
            <span>{title}</span>
            <ShiningEffect
              style={{ borderRadius: borderRadius }}
              animationDuration="6s"
              animationDelay="0.45s"
              iterationCount={blink ? `1` : `0`}
              forceBgColor="dark"
            />
          </p>
        </div>
      )}
      {children}
    </SectionBlock>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  titleStyle: PropTypes.objectOf(PropTypes.any),
  icon: PropTypes.node,
  blink: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
}

Section.defaultProps = {
  title: ``,
  blink: false,
}

export default Section
