const randomString = {
  pageName: {
    en: `Random String`,
    th: `สุ่มสตริง`,
  },
  title: {
    en: `Random String`,
    th: `สุ่มสตริง`,
  },
  length: {
    en: `Length`,
    th: `ความยาว`,
  },
  charset: {
    en: `Charset`,
    th: `ชุดอักขระ`,
  },
  charsetMenu: {
    alphanumeric: {
      en: `Alphanumeric`,
      th: `ตัวอักษรและตัวเลข`,
    },
    alphabetic: {
      en: `Alphabetic`,
      th: `ตัวอักษร`,
    },
    numeric: {
      en: `Numeric`,
      th: `ตัวเลข`,
    },
    hex: {
      en: `Hex`,
      th: `เลขฐาน 16`,
    },
    binary: {
      en: `Binary`,
      th: `Binary`,
    },
    custom: {
      en: `Custom`,
      th: `กำหนดเอง`,
    },
  },
  capitalization: {
    en: `Capitalization`,
    th: `การใช้อักษรตัวพิมพ์ใหญ่`,
  },
  capitalizationMenu: {
    any: {
      en: `Any`,
      th: `ทั้งหมด`,
    },
    lowercase: {
      en: `lowercase`,
      th: `ตัวพิมพ์เล็ก`,
    },
    uppercase: {
      en: `UPPERCASE`,
      th: `ตัวพิมพ์ใหญ่`,
    },
  },
  random: {
    en: `Random`,
    th: `สุ่ม`,
  },
  restore: {
    en: `Restore`,
    th: `คืนค่าเดิม`,
  },
  copied: {
    en: `Copied`,
    th: `คัดลอกแล้ว`,
  },
}

export default randomString
