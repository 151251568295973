import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import { useSelector } from "react-redux"

const getSpeed = option => {
  switch (option) {
    case `fast`:
      return 3

    case `normal`:
      return 4

    case `slow`:
      return 5

    default:
      return 4
  }
}

const speed = getSpeed(`fast`)

const animationKeyframe = theme => keyframes`
  0% {
    left: -15%;
    transform: scaleX(0) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0);
  }
  ${speed * 1}% {
    left: -15%;
    transform: scaleX(0) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0.45);
  }
  ${speed * 2}% {
    left: 10%;
    transform: scaleX(1) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0.9);
  }
  ${speed * 3}% {
    left: 35%;
    transform: scaleX(1) rotate(10deg);
  }
  ${speed * 4}% {
    left: 60%;
    transform: scaleX(1) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0.9);
  }
  ${speed * 5}% {
    left: 100%;
    transform: scaleX(0) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0.45);
  }
  100% {
    left: 100%;
    transform: scaleX(0) rotate(10deg);
    background-color: rgba(${
      theme === `light` ? `250, 250, 250` : `255, 255, 255`
    }, 0);
  }
`

const backgroundKeyframe = theme => keyframes`
  0% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0);
  }
  ${speed * 1}% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0);
  }
  ${speed * 2}% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0.6);
  }
  ${speed * 3}% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0.6);
  }
  ${speed * 4}% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0.6);
  }
  ${speed * 5}% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0);
  }
  100% {
    background-color: rgba(${
      theme === `light` ? `200, 200, 200` : `100, 100, 100`
    }, 0);
  }
`

const Animation = styled.span`
  display: flex;
  height: 200%;
  width: ${({ width }) => width}%;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: -45%;

  /* initial state */
  left: -15%;
  transform: scaleX(0) rotate(10deg) translate(-50%, -50%);

  animation-name: ${({ forceBgColor, theme }) => {
    switch (forceBgColor) {
      case `light`:
        return animationKeyframe(`light`)

      case `dark`:
        return animationKeyframe(`dark`)

      default:
        return theme === `light`
          ? animationKeyframe(`light`)
          : animationKeyframe(`dark`)
    }
  }};
  animation-duration: ${({ animationDuration }) => animationDuration};
  animation-delay: ${({ animationDelay }) => animationDelay};
  animation-timing-function: linear;
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
`

const BackGround = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  overflow: hidden;

  /* initial state */
  background-color: rgba(255, 255, 255, 0);

  animation-name: ${({ forceBgColor, theme }) => {
    switch (forceBgColor) {
      case `light`:
        return backgroundKeyframe(`light`)

      case `dark`:
        return backgroundKeyframe(`dark`)

      default:
        return theme === `light`
          ? backgroundKeyframe(`light`)
          : backgroundKeyframe(`dark`)
    }
  }};
  animation-duration: ${({ animationDuration }) => animationDuration};
  animation-delay: ${({ animationDelay }) => animationDelay};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
`

const ShiningEffect = ({
  style,
  width,
  animationDuration,
  animationDelay,
  iterationCount,
  forceBgColor,
}) => {
  const { theme } = useSelector(({ mainReducer }) => mainReducer)

  return (
    <>
      <BackGround
        style={style}
        animationDuration={animationDuration}
        animationDelay={animationDelay}
        iterationCount={iterationCount}
        forceBgColor={forceBgColor}
        theme={theme}
      >
        <Animation
          width={width}
          animationDuration={animationDuration}
          animationDelay={animationDelay}
          iterationCount={iterationCount}
          forceBgColor={forceBgColor}
          theme={theme}
        />
      </BackGround>
    </>
  )
}

ShiningEffect.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  width: PropTypes.number,
  animationDuration: PropTypes.string,
  animationDelay: PropTypes.string,
  iterationCount: PropTypes.string,
  forceBgColor: PropTypes.string,
}

ShiningEffect.defaultProps = {
  style: {},
  width: 25,
  animationDuration: `5s`,
  animationDelay: `0s`,
  iterationCount: `infinite`,
  forceBgColor: ``,
}

export default ShiningEffect
